import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Images/logo1.jpg'


export default function Navbar() {
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-primary text ">
  <div className="container ">
    <Link className="navbar-brand text-light" to="/"><img src={logo} style={{width:'110px',height:'50px'}} alt="MSF's Sanam - Indeed Pure Love" /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active float-right text-light" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-right text-light" to="/poems">Peoms</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-right text-light" to="/stories">Stories</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-right text-light" to="/contactus">Contact Us</Link>
        </li>
        
              </ul>
    
    </div>
  </div>
</nav>
    </>
  )
}
