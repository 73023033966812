import React from 'react'

export default function ContactUs() {
  return (
    <div className='container'>
     <p style={{marginTop:'20px'}}>Please contact us or share your feedback.your feedback is important to improve our website.</p>
      <div className="mb-3">
  <label htmlfor="exampleFormControlInput1" className="form-label" required>Name</label>
  <input type="text" className="form-control" id="name" placeholder="Your Name"/>
</div>
      
<div className="mb-3">
  <label htmlfor="exampleFormControlInput1" className="form-label" required>Email address</label>
  <input type="email" className="form-control" id="email" placeholder="name@example.com"/>
</div>
<div className="mb-3">
  <label htmlfor="exampleFormControlTextarea1" className="form-label" required>Description </label>
  <textarea className="form-control" id="description" rows="3"></textarea>
</div>
<div className='container d-flex justify-content-center' style={{marginBottom:'20px'}}>
<button type="button" className="btn btn-primary btn-lg  ">Submit</button>
</div>
    </div>
  )
}
