
import './App.css';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Poems from './Components/Poems';
import jsondata from './Data.json';
import Stories from './Components/Stories';

import './style.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";


function App() {

  const root={child: jsondata}
  return (
    <>
      <div className='my-component'>

        <Router >
        <Navbar />
          <Routes>
         
            <Route path="/poems" element={<Poems  />}/>
              <Route path="/contactus" element={<ContactUs />}/>
              <Route path="/stories" element={<Stories />}/>
                <Route path="/" element={<Home />}/>
               
                </Routes>

                <Footer />


              </Router>
            </div>

          </>


          );
}

          export default App;
