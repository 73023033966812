import React from 'react'
import s1 from '../Images/Mypeace (2).jpg'
import s2 from '../Images/dark (2).jpg'
import s4 from '../Images/nomatter1.jpg'
import sanam from '../Images/Sanam.jpg'

export default function Home() {
  return (
    <>
 
    <div className='container' style={{marginTop:'20px'}}>

    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" >
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={s2} className="d-block  w-100" alt="Whether it is dark or light, I will be there to protect you"/>
    </div>
    <div className="carousel-item">
      <img src={s1} className="d-block w-100" alt="You are my peace, my breath, my life, my queen, my everything"/>
    </div>
    <div className="carousel-item">
      <img src={s4} className="d-block w-100" alt="No matter how far you are, I am always there holding your hands"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>

</div>
        <h1 className='text-center' style={{marginTop:'50px'}}>Eternal Love and Devotion For My "Sanam"</h1>
     

        <p style={{marginTop:'20px'}}> My love for you is like the gentle embrace of a warm summer breeze, an ever-present and comforting force that fills my heart with boundless joy. With every beat of my heart, it's you who occupies my thoughts, your smile that brightens my darkest days, and your presence that completes the very essence of my existence.</p>
        <p>In your eyees, I find a universe of wonder, a reflection of the beauty that resides within your soul. Your laughter is the sweetest melody, and your touch is a tender caress that ignites a fire of passion within me. Each moment we share, whether it's a stolen glance, a whispered secret, or a lingering kiss, deepens the profound connection that binds us together.</p>
        <div className='d-flex justify-content-center'>
        <img src={sanam} className="img-thumbnail" alt="MSF's Sanam" style={{width:'200px',height:'200px'}}/>
        </div>
   
    <p>You are the sun that rises in my life, casting away the shadows of doubt and filling my world with warmth and radiance. In your love, I have found my anchor, my sanctuary, and my greatest source of strength. With you, every day is an adventure, and every challenge is an opportunity to grow stronger together.</p>
    <p>My love for you is an unwavering, enduring force, a love that knows no bounds and only grows stronger with time. You are my heart's desire, my soul's counterpart, and my forever love. With you, I have found my home, and I am eternally grateful for the love we share.</p>
    </div>

    </>
  
  )
}
