import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (

    
    <>
    
    <footer className="bg-primary text-white py-10">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <ul className="list-inline">
          <nav className="navbar navbar-light bg-primary">
  <div className="container-fluid">
    <Link className="navbar-brand text-white" to="/">Home</Link>
  </div>
</nav>
<nav className="navbar navbar-light bg-primary">
  <div className="container-fluid">
    <Link className="navbar-brand text-white" to="/poems">Poems</Link>
  </div>
</nav>
<nav className="navbar navbar-light bg-primary">
  <div className="container-fluid">
    <Link className="navbar-brand text-white" to="/stories">Stories</Link>
  </div>
</nav>
<nav className="navbar navbar-light bg-primary">
  <div className="container-fluid">
    <Link className="navbar-brand text-white" to="/contactus">Contact Us</Link>
  </div>
</nav>
            </ul>
          </div>
          <div className="col-md-6">
            <h4>Follow Us</h4>
            {/* Social Media Links */}
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="#" className="btn btn-outline-light"><i className="fab fa-facebook"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="btn btn-outline-light"><i className="fab fa-twitter"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="btn btn-outline-light"><i className="fab fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div className='container text-center'>
            <p>© 2012-2023 MSF's Sanam. All Rights Reserved.</p>
            <p>Devloped & Maintained By MSF.</p>

        </div>
      </div>
    </footer>
    </>
  )
}
