import React, { useState }  from 'react'
import data from '../Data.json'



export default function Poems() {
  const [openAccordion, setOpenAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };


  const poemsData = data.find(item => item.datatype === 'poem');
  const poemDescs = poemsData
  ? poemsData.poems.map(poem => ({
      heading: poem.heading,
      poem_desc: poem.poem_desc,
    }))
  : [];

console.log(poemDescs);






  return (
    <>
      
    <div  className='container'>
     <p style={{marginTop:'20px'}}>Love is a feeling, pure and true, a bond that connects me to you. It's more than words; it's beyond compare—a journey of the heart. Love is a whisper in the silent night, a guiding star, and a beacon of light. Love is a promise and a sacred vow. To stand by each other, here and now.It's the strength to endure, the will to forgive. A love that's boundless, for as long as we live.</p>
   <h2 className='text-center'> Here are some poems to express my love for my "Sanam." </h2>
   {poemDescs.map((poem, index) => (
    <div  key={index} className="accordion" id="accordionExample" style={{marginTop:'20px', marginBottom:'20px'}}>
  <div className="accordion-item">
    <h2 className="accordion-header" id={`heading${index}`}>
   
      <button className={`accordion-button ${openAccordion === index ? 'collapsed' : ''}`} type="button" onClick={() => handleAccordionClick(index)}
                  aria-expanded={openAccordion === index}
                  aria-controls={`collapse${index}`} data-bs-toggle="collapse" 
                 >
      {poem.heading}
      </button>
    </h2>
   
    <div id={`collapse${index}`} className={`accordion-collapse collapse ${openAccordion === index ? 'show' : ''}`}
                aria-labelledby={`heading${index}`} >
      <div className="accordion-body">
        <strong>{poem.poem_desc}</strong> 
      </div>

    </div>
      
  </div>
  
 
</div>
    ))}
</div>
</>
  
  )
}
